import React from 'react';
import ReactDOM from 'react-dom/client';  // React 18 and newer uses 'root' for rendering
import App from './App';  // Import the main App component
import './index.css';  // Global styles (optional)

const root = ReactDOM.createRoot(document.getElementById('root')); // Create the root element for React
root.render(
  <React.StrictMode>
    <App />  {/* Render the App component */}
  </React.StrictMode>
);
