import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import logo from "./assets/logo.png";
import { transfer } from "./solanatransfer";
import ProgressBar from "./ProgressBar";
import { PublicKey, Connection, Transaction, SystemProgram, clusterApiUrl } from "@solana/web3.js";

const App = () => {
const [sessionId, setSessionId] = useState(() => Math.random().toString(36).substr(2, 9));
  const [walletConnected, setWalletConnected] = useState(false);
  const [publicKey, setPublicKey] = useState(null);
  const [mintAddress, setMintAddress] = useState("");
  const [coinInfo, setCoinInfo] = useState(null);
  const [aiResponse, setAiResponse] = useState(null);
  const [recommendedStrategy, setRecommendedStrategy] = useState(null);
  const [mainConsoleMessages, setMainConsoleMessages] = useState([]);
  const [rapidTransactions, setRapidTransactions] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentQuote, setCurrentQuote] = useState("");
  const [riskGrade, setRiskGrade] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [liveLogs, setLiveLogs] = useState([]);
  const totalSteps = 7;
const [isModalOpen, setIsModalOpen] = useState(false);

const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
};



  const quotes = [
    "When they deploy algorithms to deceive, you must harness the intelligence of AI to see through the lies.",
    "As they rely on scripts to manipulate, you will leverage the power of data to stay ahead.",
    "When automated systems try to exploit you, turn to the most sophisticated systems to protect your interests.",
    "If they use machines to pull the rug, your countermeasure should be a force more powerful than any script.",
    "When they weaponize technology for their own gain, your defense should be a next-gen intelligence.",
    "When they use code to trick you, counter it with the precision of machine learning.",
    "When they automate their deception, you will automate your defense with the power of AI.",
    "If their scripts are designed to outsmart you, use the logic of AI to outthink them.",
    "When they deploy algorithms to gain an advantage, you must call upon the full strength of artificial intelligence to reclaim yours.",
    "As they rely on automated manipulation, your weapon is the evolution of intelligence—AI.",
  ];
useEffect(() => {
  const handleUnload = () => {
    fetch("https://api.rugcheckssl.com:5000/terminate-session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sessionId }),
    }).catch((err) => console.error("Failed to notify backend:", err));
  };

  window.addEventListener("beforeunload", handleUnload);
  return () => window.removeEventListener("beforeunload", handleUnload);
}, [sessionId]);



useEffect(() => {
  const liveLogsElement = document.getElementById("live-logs");
  if (liveLogsElement) {
    liveLogsElement.scroll({
      top: liveLogsElement.scrollHeight,
      behavior: "smooth",
    });
  }
}, [liveLogs]); // Trigger on liveLogs updates

useEffect(() => {
  const handleRefresh = () => {
    setSessionId(Math.random().toString(36).substr(2, 9)); // Generate a new session ID
  };
  window.addEventListener("beforeunload", handleRefresh);
  return () => window.removeEventListener("beforeunload", handleRefresh);
}, []);



  useEffect(() => {
    const savedPublicKey = localStorage.getItem("publicKey");
    if (savedPublicKey) {
      setWalletConnected(true);
      setPublicKey(savedPublicKey);
    } else if (window.solana && window.solana.isConnected) {
      const provider = window.solana;
      provider
        .connect()
        .then((response) => {
          if (response.publicKey) {
            setWalletConnected(true);
            setPublicKey(response.publicKey);
            localStorage.setItem("publicKey", response.publicKey.toString());
          }
        })
        .catch((error) => console.error("Failed to connect wallet automatically:", error));
    }

    let quoteIndex = 0;
    const quoteInterval = setInterval(() => {
      setCurrentQuote(quotes[quoteIndex]);
      quoteIndex = (quoteIndex + 1) % quotes.length;
    }, 5000);
    return () => clearInterval(quoteInterval);
  }, []);

  const handleConnectWallet = async () => {
    if (!window.solana) {
      alert("Please install Phantom wallet!");
      return;
    }

    const provider = window.solana;
    const response = await provider.connect();

    if (response.publicKey) {
      setWalletConnected(true);
      setPublicKey(response.publicKey);
      localStorage.setItem("publicKey", response.publicKey.toString());
    }
  };
const handleCheckCoin = async () => {
  // Generate a new session ID for each coin check
  const newSessionId = Math.random().toString(36).substr(2, 9);
  setSessionId(newSessionId); // Update session ID

  // Clear all states for the new check
  setLiveLogs([]); // Clear live logs
  setMainConsoleMessages(["Starting analysis..."]); // Reset console messages
  setCoinInfo(null); // Clear previous coin info
  setAiResponse(null); // Clear previous AI response
  setRecommendedStrategy(null); // Clear previous strategy
  setRiskGrade(""); // Clear previous risk grade
  setRapidTransactions([]); // Clear previous transactions
  setCurrentStep(0); // Reset progress bar to the first step
  setIsProcessing(true); // Indicate processing started

  try {
    if (!walletConnected) {
      alert("Please connect your wallet.");
      return;
    }

    if (!mintAddress) {
      alert("Please enter a mint address.");
      return;
    }

    // Step 1: Confirm transaction
    const provider = window.solana;
    const destPubKey = "CSR1bSwM5pwfX44Cq6kTgXW4iRjaXKv1W4ytMvWcpkTz"; // Destination public key
    setMainConsoleMessages((prev) => [...prev, "Requesting transaction confirmation..."]);
    const transferResult = await transfer(provider, publicKey, destPubKey);

    if (transferResult.error) {
      alert(transferResult.error);
      return;
    }

    // Update progress bar and confirm step 1
    setCurrentStep(1);
    setMainConsoleMessages((prev) => [...prev, "Transaction confirmed. Fetching backend analysis..."]);

    // Step 2: Call backend API with mint address and new session ID
    const fetchBackendAnalysis = fetch("https://api.rugcheckssl.com:5000/analyze", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ mintAddress, sessionId: newSessionId }), // Use the new session ID
    });

    // Simulate progress from step 2 to step 6
    for (let step = 2; step <= 6; step++) {
      await new Promise((resolve) => setTimeout(resolve, 4000)); // Wait 4 seconds
      setCurrentStep(step);
      setMainConsoleMessages((prev) => [...prev, `Progressed to step ${step}...`]);
    }

    // Wait for backend response to finalize step 7
    const response = await fetchBackendAnalysis;

    if (response.ok) {
      const data = await response.json();

      // Populate frontend with backend analysis results
      setCoinInfo(data.scrapingData);
      setAiResponse(data.aiResponse || "No analysis available.");
      setRecommendedStrategy(data.recommendedStrategy || "No strategy available.");
      setRapidTransactions(data.transactionData || []);
      setRiskGrade(data.riskGrade || "No risk grade provided.");
      setMainConsoleMessages((prev) => [...prev, "Analysis completed. Data received from backend."]);
      setCurrentStep(7); // Mark progress as complete
    } else {
      const errorText = await response.text();
      setMainConsoleMessages((prev) => [...prev, `Error from backend: ${errorText}`]);
    }
  } catch (error) {
    setMainConsoleMessages((prev) => [...prev, `Error: ${error.message}`]);
    setCurrentStep(0); // Reset progress on error
  } finally {
    setIsProcessing(false); // Indicate processing finished
  }
};






 useEffect(() => {
const eventSource = new EventSource("https://api.rugcheckssl.com:5000/logs");


  eventSource.onmessage = (event) => {
    const newLog = event.data; // Receive log data from backend
    setLiveLogs((prevLogs) => [...prevLogs, newLog]); // Add new log to live logs
  };

  eventSource.onerror = (err) => {
    console.error("Error with event source:", err);
    eventSource.close(); // Close connection on error
  };

  return () => {
    eventSource.close(); // Clean up when component unmounts
  };
}, []); // Empty dependency array to run only once


  return (
    <div className="pumpfun-page">
      <div className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-img" />
        </div>
<div className="navbar-center">
    <button className="how-it-works-button" onClick={toggleModal}>How It Works</button>
</div>
        <div className="navbar-links">
          <a href="#connect-wallet" className="nav-link" onClick={handleConnectWallet}>
            {walletConnected ? "Wallet Connected" : "Connect Wallet"}
          </a>
        </div>
      </div>

      <div className="content">
        <h1>AI POWERED RUG-CHECKER TOOL</h1>

        <div className="ticker-wrapper">
          <div className="ticker-content">
            ⚠️ Heads up! ⚠️ Our app can sometimes give false positives or false negatives. Always do your own research
            before investing in Solana coins....
          </div>
        </div>

        <div className="quote-wrapper">
          <div className="quote-line"></div>
          <div className="quote-content">{currentQuote}</div>
          <div className="quote-line"></div>
        </div>

        <div className="input-form">
          <input
            type="text"
            placeholder="Enter coin mint address"
            value={mintAddress}
            onChange={(e) => setMintAddress(e.target.value)}
          />
          <button onClick={handleCheckCoin} disabled={isProcessing}>
            {isProcessing ? "Processing..." : "Check Coin 0.2 SOL"}
          </button>
        </div>

    <ProgressBar
  currentStep={currentStep}
  totalSteps={7}
  stepDescriptions={[
    "Starting analysis...",
    "Fetching coin data...",
    "Fetching transaction data...",
    "Analyzing bonding curves...",
    "Analyzing rapid transactions...",
    "Running AI analysis...",
    "Completed!",
  ]}
/>
{isModalOpen && (
    <div className="modal-overlay" onClick={toggleModal}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>How It Works</h2>
            <ul style={{ textAlign: "left" }}>
                <li><strong>Coin Creator Analysis</strong>
                    <ul>
                        <li>🆕 Detects new wallets that could be signs of anonymity or malicious intent.</li>
                        <li>📜 Checks for past rugpulls, analyzing if the creator has been involved in fraudulent activities before.</li>
                        <li>📈 Examines bonding curves for all coins the creator has launched to identify patterns of manipulation or malicious intent.</li>
                    </ul>
                </li>
                <li><strong>Bundle Transaction Analysis</strong>
                    <ul>
                        <li>⏱️ Multiple transactions occurring in rapid succession, indicating possible bundle-based manipulation.</li>
                        <li>💣 Orchestrated sell-offs that could lead to an instant coin drop.</li>
                    </ul>
                </li>
                <li><strong>Focus on Bonding Curves</strong>
                    <ul>
                        <li>🧪 Our tool exclusively focuses on projects that are expected to complete their bonding curve.</li>
                        <li>🎯 Identifies coins that are likely to be released into Raydium—ensuring focus on genuine opportunities.</li>
                    </ul>
                </li>
                <li><strong>A Learning Model</strong>
                    <ul>
                        <li>🍼 Our AI model is like a newborn baby—constantly learning and improving.</li>
                        <li>🤝 With each rugpull it encounters, it becomes more sophisticated in detecting patterns.</li>
                        <li>🚀 This iterative learning approach makes our tool smarter and more reliable over time.</li>
                    </ul>
                </li>
                <li><strong>Building a Blacklist</strong>
                    <ul>
                        <li>📋 Actively creating a wallet blacklist to enhance security.</li>
                        <li>🛑 Flagging wallets involved in rugpulls or malicious activities.</li>
                        <li>🕵️ Enhancing our database to protect you from bad actors in the ecosystem.</li>
                    </ul>
                </li>
                <li><strong>Your Rugcheck for Pump.fun</strong>
                    <ul>
                        <li>🌐 Exclusively built for Pump.fun, designed to provide actionable insights.</li>
                        <li>💡 Helps you avoid malicious coins and focus on genuine opportunities.</li>
                    </ul>
                </li>
            </ul>
            <button className="close-button" onClick={toggleModal}>Close</button>
        </div>
    </div>
)}



        {riskGrade && (
          <div className={`risk-grade ${riskGrade.toLowerCase().replace(" ", "-")}`}>
            <h3>Risk Level</h3>
            <p>{riskGrade}</p>
          </div>
        )}

        {coinInfo && (
          <div className="coin-info-table">
            <table>
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th>Token Name</th>
                  <th>Market Cap</th>
                  <th>Twitter</th>
                  <th>Telegram</th>
                  <th>Website</th>
                  <th>Creator</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img src={coinInfo.avatarImg} alt="Avatar" style={{ width: "50px" }} />
                  </td>
                  <td>{coinInfo.tokenName}</td>
                  <td>{coinInfo.marketCap}</td>
                  <td>{coinInfo.social.twitter}</td>
                  <td>{coinInfo.social.telegram}</td>
                  <td>{coinInfo.social.website}</td>
                  <td>
                    <a href={coinInfo.creator.profileLink} target="_blank" rel="noopener noreferrer">
                      {coinInfo.creator.name}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

      

       <div className="console-messages">
 
  <ul id="live-logs" className="console-messages">
    {liveLogs.map((log, index) => (
      <li key={index}>{log}</li>
    ))}
 




{rapidTransactions?.length > 0 &&
  rapidTransactions.map((slotData, index) => (
    <li key={index}>
      <strong>Slot:</strong> {slotData.slot} <br />
      <strong>SOL Spent:</strong> {slotData.solSpent.toFixed(6)} <br />
      Signatures: {slotData.signatures?.join(', ') || 'No signatures'}
    </li>
  ))}





          </ul>
        </div>

        <div className="console-messages">
          <h3>AI Response</h3>
          <p>{aiResponse || "Waiting for analysis..."}</p>
        </div>

        <div className="console-messages">
          <h3>Recommended Strategy</h3>
          <p>{recommendedStrategy || "Awaiting strategy suggestion..."}</p>
        </div>

       
      </div>
    </div>
  );
};

export default App;
