import React, { useState, useEffect } from 'react';
import './ProgressBar.css'; // Ensure your CSS is set up for styling

const ProgressBar = ({ currentStep, totalSteps, stepDescriptions }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Update the progress bar width based on the current step
    const progressPercentage = (currentStep * 100) / totalSteps;
    setProgress(progressPercentage);
  }, [currentStep, totalSteps]);

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      <div className="loading-indicator"></div>
      <div className="progress-line" style={{ width: `${progress}%` }}></div>
      <div className="steps">
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`step ${index < currentStep ? 'active' : ''}`}
          >
            {/* Use stepDescriptions if provided */}
            <span>{stepDescriptions && stepDescriptions[index] ? stepDescriptions[index] : `Step ${index + 1}`}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
