import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import * as buffer from "buffer";

// Set up Buffer for use in the browser environment
window.Buffer = buffer.Buffer;

// Connect to Solana Devnet (using the Devnet RPC URL)
const heliusRpcUrl = "https://api.devnet.solana.com";
const connection = new Connection(heliusRpcUrl, "confirmed");

// Function to transfer SOL from one account to another
export const transfer = async (provider, publicKey, destPubKey) => {
  console.log("Public Key:", publicKey.toString());
  console.log("Destination Public Key:", destPubKey);

  const initialBalance = await getSol(publicKey);
  console.log("Initial Balance: ", initialBalance);

  // Ensure publicKey and destPubKey are PublicKey instances
  let senderPublicKey = new PublicKey(publicKey);
  let receiverPublicKey = new PublicKey(destPubKey);

  console.log("Initializing the transaction...");

  let transaction = new Transaction({
    feePayer: senderPublicKey,
    recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
  });

  const amount = 0.04 * LAMPORTS_PER_SOL; // Amount to transfer in lamports

  try {
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: senderPublicKey,
        toPubkey: receiverPublicKey,
        lamports: amount,
      })
    );
  } catch (e) {
    console.error("Error creating public key:", e);
    return { error: "Invalid public key format." };
  }

  console.log("Signing the transaction...");

  try {
    // Make sure the provider is connected and valid
    if (!provider || !provider.signTransaction) {
      throw new Error("Provider is not connected properly");
    }

    transaction = await provider.signTransaction(transaction); // Sign the transaction using the provider (e.g., Phantom)

  } catch (err) {
    console.error("Error signing transaction:", err);
    return { error: "Transaction was rejected or provider issue." };
  }

  console.log("Sending the transaction to the network...");
  try {
    // Send the signed transaction
    const id = await connection.sendRawTransaction(transaction.serialize(), {
      skipPreflight: false,
    });

    console.log(`Transaction ID: ${id}`);

    const confirmation = await connection.confirmTransaction(id);
    console.log(`Transaction confirmed in slot: ${confirmation.context.slot}`);

    const myBalance = await getSol(senderPublicKey);
    return {
      success: true,
      transactionId: id,
      balance: myBalance,
    };
  } catch (e) {
    console.error("Error sending or confirming transaction:", e);
    return { error: "Transaction failed" };
  }
};

// Function to get the SOL balance of a given public key
const getSol = async (key) => {
  try {
    const balance = await connection.getBalance(key);
    return balance / LAMPORTS_PER_SOL; // Convert lamports to SOL
  } catch (e) {
    console.error("Error fetching balance:", e);
    return 0;
  }
};
